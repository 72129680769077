<!--
	作者：jyl
	时间：2017-07-26
	描述：管理用户页面
-->
<template>
  <div class="page1">
    <!--主体内容-->
    <!-- <div class="breadcrumb"></div> -->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :model="formInline"
          ref="formInline"
          :inline="true"
          label-position="right"
          label-width="82px"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')" style="" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    v-for="value in roleList"
                    :key="value.operationId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
                <el-input
                  :maxlength="11"
                  size="15"
                  v-model.trim="formInline.mobile"
                  placeholder="输入手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.full_name')" prop="operationManagerName">
                <el-input
                  size="15"
                  v-model.trim="formInline.operationManagerName"
                  placeholder="输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.administrator_account')" prop="account">
                <el-input
                  :maxlength="20"
                  size="15"
                  v-model.trim="accountTolowerCase"
                  placeholder="请输入用户名"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')" prop="operationManagerState">
                <el-select v-model.trim="formInline.operationManagerState" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="使用" value="1"></el-option>
                  <el-option label="停用" value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.add">
            <div class="col_left">
              <router-link :to="{ path: '/operatorAdministratorsManageAE' }">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  v-if="$route.meta.authority.button.add"
                  >{{ $t('button.addto') }}</el-button
                >
              </router-link>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 12px; text-align: center"
        >
          <!--<el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column :label="$t('searchModule.state')" width="80" align="center">
            <template slot-scope="scope">
              <span
                ><i
                  :class="
                    scope.row.operationManagerState == 1
                      ? 'el-icon-circle-check'
                      : 'el-icon-circle-close'
                  "
                  :style="{
                    color: scope.row.operationManagerState == 1 ? 'green' : 'red',
                  }"
                ></i
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120"
            align="center"
            v-if="
              $route.meta.authority.button.view ||
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.reset ||
              $route.meta.authority.button.disable
            "
          >
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1" v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item command="2" v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="3" v-if="$route.meta.authority.button.reset"
                    >重置密码</el-dropdown-item
                  >
                  <el-dropdown-item command="4" v-if="$route.meta.authority.button.disable">{{
                    scope.row.operationManagerState == 1 ? "禁用" : "启用"
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setIndex, jsMd5 } from "@/common/js/public.js";
export default {
  name: "operatorAdministratorsManage",
  data() {
    return {
      rowData: "",
      index: 0,
      total: 0,
      pageSize: 15,
      isEdit: false,
      pageNum: 1,
      userId: "",
      loading: false,
      dialogFormVisible: false,
      dialogVisible: false,
      areaList1: "",
      roleList: [],
      areaList: [],
      limitList: [],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },

        {
          prop: "businessTypeName",
          label: "经营业务类型",
          width: "",
        },
        {
          prop: "account",
          label: "管理员账号",
          width: "130",
        },
        {
          prop: "operationManagerName",
          label: "管理员姓名",
          width: "",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "120",
        },
        {
          prop: "email",
          label: "邮箱",
          width: "120",
        },
        {
          prop: "sex",
          label: "性别",
          width: "80",
          formatter: (row, column, cellValue, index) => {
            return cellValue == 1 ? "男" : "女";
          },
        },
        {
          prop: "createdTime",
          label: this.$t("list.add_time"),
          width: "200",
        },
      ],
      tableData: [],
      formInline: {
        operationId: "",
        operationManagerState: "",
        parkTenantId: "",
        mobile: "",
        realName: "",
        managerId: "",
        account: "",
        companyId: "",
        personName: "",
        operationManagerName: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.$refs.formInline.resetFields();
      this.formInline.operationId = "";
    },
    // resetSeret
    resetSeret({ operationManagerId, account }) {
      this.$confirm("此操作将重置该用户密码, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get("/acb/2.0/operationManager/resetpassword", {
              data: {
                operationManagerId,
                password: jsMd5(account, "Zhht@2019"),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "success",
                  message: "重置成功",
                });
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 操作细则
    handleCommand(command, data) {
      if (command == 1) {
        this.$router.push({
          path: "/operatorAdministratorsManageDetail",
          query: data,
        });
      } else if (command == 2) {
        this.$router.push({
          path: "/operatorAdministratorsManageAE",
          query: data,
        });
      } else if (command == 3) {
        this.resetSeret(data);
      } else if (command == 4) {
        this.managerUser(data);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 处理操作相关动作
    managerUser(systemData) {
      var tip = systemData.operationManagerState == 1 ? "禁用" : "启用";
      this.$confirm("此操作将" + tip + "该用户, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get("/acb/2.0/operationManager/updatestatus", {
              data: {
                operationManagerId: systemData.operationManagerId,
                operationManagerState: systemData.operationManagerState == 1 ? 2 : 1,
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: systemData.operationManagerState == "1" ? "禁用成功!" : "启用成功!",
              });
              this.searchData();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消" + tip,
          });
        });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/operationManager/list", {
          data: Object.assign(this.formInline, {
            page: this.pageNum,
            size: this.pageSize,
          }),
        })
        .then((res) => {
          if (!res.value) {
            this.total = 0;
          }
          this.loading = false;
          this.total = (res.value && res.value.total * 1) || 0;
          this.tableData = setIndex(this.pageNum, res.value.list);
        });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.roleList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 提交数据
    submitData() {
      if (this.isEdit) {
        // 修改管理员
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.update();
          } else {
            return false;
          }
        });
      } else {
        // 添加管理员
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.addUser();
          } else {
            return false;
          }
        });
      }
    },
    /* add (data) {
      if (this.isEdit) {
        this.dialogFormVisible = true;
        let {systemId, systemName, account, mobile, systemRoleId, email, sex, systemType = '1'} = data;
        this.formUpdate = {systemId, systemName, account, mobile, systemRoleId, email, sex, systemType};
      } else {
        this.dialogVisible = true;
        this.$refs.form && this.$refs.form.resetFields();
      }
    } */
  },
  components: {},
  activated() {
    if (this.$route.query.operationId) {
      this.formInline.operationId = this.$route.query.operationId;
    }
    this.searchData();
  },
  created() {
    this.getRoleList();
  },
  mounted() {},
  computed: {
    accountTolowerCase: {
      get: function () {
        return this.formInline.account;
      },
      set: function (val) {
        this.formInline.account = val.toLowerCase();
      },
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
